import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import * as Icon from 'react-feather'

type Props = {
  scrollStepInPx: number
  delayInMs: number
}

const GoTop: React.FC<Props> = ({ scrollStepInPx, delayInMs }) => {
  const [thePosition, setThePosition] = useState(false)
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 170) {
        setThePosition(true)
      } else {
        setThePosition(false)
      }
    })
  }, [])

  const onScrollStep = () => {
    if (window.pageYOffset === 0 && timeoutRef.current != null) {
      window.clearInterval(timeoutRef.current)
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx)
  }

  const scrollToTop = () => {
    timeoutRef.current = window.setInterval(onScrollStep, delayInMs)
  }

  const renderGoTopIcon = () => {
    return (
      <div
        className={`go-top ${thePosition ? 'active' : ''}`}
        onClick={scrollToTop}
      >
        <Icon.ArrowUp />
      </div>
    )
  }

  return renderGoTopIcon()
}

export default GoTop

import * as React from 'react'
import Helmet from 'react-helmet'
import { PageProps, useStaticQuery, graphql } from 'gatsby'
import urljoin from 'url-join'

import GoTop from '@base/components/app/GoTop'
import Navbar from '@base/components/app/Header'
import Footer from '@base/components/app/Footer'
import { adobeLoader } from './fonts/adobeLoader'

import favicon from '@base/images/meta/favicon.png'
import appTouchIcon from '@base/images/meta/app-touch-icon.png'
import ogimg from '@base/images/meta/ogimg.jpg'

import '@base/css/libraries/bootstrap.min.css'
import 'animate.css'
import '@base/css/libraries/boxicons.min.css'
import '@base/css/libraries/flaticon.css'
import '@base/css/libraries/slick.css'
import 'react-accessible-accordion/dist/fancy-example.css'
import '@base/css/style.scss'

const SITE_TITLE = '学校法人市邨学園 名古屋経済大学市邨中学校 市邨高等学校'
const DEFAULT_DESCRIPTION =
  'ICHIMURA SENIOR | JUNIOR HIGH SCHOOL 建学の精神、一に人物、二に技倆。「111年目のIchimura」がいま進めているのは、未来を拓く教育です。 〒464-8533 名古屋市千種区北千種3-1-37 TEL 052-721-0161 FAX 052-721-0211'
const TITLE_TEMPLATE = `%s | ${SITE_TITLE}`

const Layout: React.FC<PageProps> = ({ path, children }) => {
  React.useEffect(() => {
    adobeLoader(document)
  }, [])

  const { site } = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
    query Layout {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  let pageUrl
  if (site?.siteMetadata?.siteUrl && path) {
    pageUrl = urljoin(site?.siteMetadata?.siteUrl, path)
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{SITE_TITLE}</title>
        <meta name="description" content={DEFAULT_DESCRIPTION} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={pageUrl} />
        <link rel="icon" type="image/png" href={favicon} />
        <link rel="apple-touch-icon" href={appTouchIcon} />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={SITE_TITLE} />
        <meta property="og:description" content={DEFAULT_DESCRIPTION} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content={SITE_TITLE} />
        <meta property="og:image" content={ogimg} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={SITE_TITLE} />
        <meta name="twitter:image" content={ogimg} />
      </Helmet>

      <GoTop scrollStepInPx={100} delayInMs={10.5} />
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

type PageSpecificProps = {
  title?: string
  meta: {
    name?: string
    property?: string
    content: string
  }[]
}

const pageSpecificProps = ({
  title,
  description,
  imageUrl,
}: {
  title?: string
  description?: string
  imageUrl?: string
}): PageSpecificProps => {
  const result: PageSpecificProps = { meta: [] }

  if (title) {
    const titleStr = TITLE_TEMPLATE.replace('%s', title)
    result.title = titleStr
    result.meta.push(
      { property: 'og:title', content: titleStr },
      { name: 'twitter:title', content: titleStr },
    )
  }

  if (description) {
    result.meta.push(
      { name: 'description', content: description },
      { property: 'og:description', content: description },
    )
  }

  if (imageUrl) {
    result.meta.push(
      { property: 'og:image', content: imageUrl },
      { name: 'twitter:image', content: imageUrl },
    )
  }

  return result
}

export default Layout
export { pageSpecificProps }

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-ichimura-base-src-pages-404-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-ichimura-base-src-pages-404-tsx" */),
  "component---gatsby-theme-ichimura-base-src-templates-blog-archive-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/templates/blog-archive.tsx" /* webpackChunkName: "component---gatsby-theme-ichimura-base-src-templates-blog-archive-tsx" */),
  "component---gatsby-theme-ichimura-base-src-templates-blog-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/templates/blog.tsx" /* webpackChunkName: "component---gatsby-theme-ichimura-base-src-templates-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

